import { useState } from "react";
import { useTeamAccount } from "~/providers/TeamAccountProvider";

export const useSettingsMenu = (): [string, (menu: string) => void] => {
  const { activeTeamAccount } = useTeamAccount();
  const isTrialSubscription =
    activeTeamAccount.stripeSubscription?.status === "trialing";

  const [activeHeaderMenu, setActiveHeaderMenu] = useState(() => {
    const menu = localStorage.getItem("settingsMenuState");
    if (menu === "Subscription" && isTrialSubscription) {
      return "Users";
    }
    return menu || "Users";
  });

  const handleSetActiveHeaderMenu = (menu: string) => {
    setActiveHeaderMenu(menu);
    if (menu === "Users" || menu === "Subscription" || menu === "Billing") {
      localStorage.setItem("settingsMenuState", menu);
    }
  };

  return [activeHeaderMenu, handleSetActiveHeaderMenu];
};
