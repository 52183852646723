import PricingModal from "./PricingModal";
function SubscriptionsPlansModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return <PricingModal isOpen={isOpen} onClose={onClose} />;
}

export default SubscriptionsPlansModal;
