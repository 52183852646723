import { useEffect, useRef } from "react";
import * as d3 from "d3";
import createVennDiagram from "./helpers/createVennDiagram";
import { regularStyle, tooltipStyle } from "./helpers/vennStyles";
import Notification from "~/components/base/Notification/Notification";
import { RouterOutputs } from "~/server/trpc/react";
import useNotificationHashCreator from "~/components/base/Notification/useNotificationHashCreator";

// for examples of venn.js: https://github.com/benfred/venn.js

export default function VennDiagram({
  overviewEvaluation,
  considerDone,
}: Readonly<{
  overviewEvaluation: RouterOutputs["overviewEvaluation"]["get"] | undefined;
  considerDone: boolean;
}>) {
  const dataSet = overviewEvaluation?.vennDataSet ?? [];
  const svgRef = useRef<SVGSVGElement | null>(null);

  const vennDiagramHash = useNotificationHashCreator({
    title: "VennDiagram",
    dataSet: dataSet.length,
  });

  useEffect(() => {
    const svgNode = createVennDiagram(dataSet, regularStyle, tooltipStyle);
    if (svgRef.current) {
      svgRef.current.innerHTML = "";
    }
    if (svgRef.current && svgNode) {
      svgRef.current.appendChild(svgNode as Node);
    }
    return () => {
      d3.select(".tooltip").remove();
    };
  }, [dataSet]);

  const evalWaiting = overviewEvaluation?.status === "waiting";
  const noUserContacts = !evalWaiting && dataSet.length === 0;
  const dataAvailable = dataSet.length > 0;

  return (
    <div className="mb-20">
      <div className="flex justify-between pt-2 mb-4 ">
        <div className="flex items-center gap-2">
          <h3 className="text-xl font-semibold">Shared Contributors</h3>
        </div>
      </div>
      <div className="w-full ">Contributors overlap between lists</div>
      {noUserContacts && (
        <div className="my-4">
          Please make sure you have added contacts to a list and run evaluations
          to see relevant statistics.
        </div>
      )}
      {evalWaiting && !considerDone && (
        <Notification
          hash={vennDiagramHash}
          id="VennDiagram"
          title={"Building"}
          isLoading={true}
          className="my-4"
        >
          Evaluation in progress.
        </Notification>
      )}
      {dataAvailable && (
        <div
          id="venn"
          ref={svgRef as React.RefObject<HTMLDivElement | null>}
          style={{ width: `900px`, height: `600px` }}
          className="m-8 max-h-[600px]"
        />
      )}
    </div>
  );
}
