import { Dialog, DialogContent, DialogTitle } from "~/components/ui/dialog";
import SubscriptionPlans from "./SubscriptionPlans";

interface PricingModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function PricingModal({ isOpen, onClose }: PricingModalProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="min-w-[1200px] flex justify-center items-center">
        <DialogTitle className="sr-only">Subscription Plans</DialogTitle>
        <SubscriptionPlans />
      </DialogContent>
    </Dialog>
  );
}
