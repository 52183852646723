import { useState } from "react";
import Link from "next/link";
import { Button } from "~/components/ui/button";
import SubscriptionsPlansModal from "~/components/settings/SubscriptionsPlansModal";
import { useSettingsMenu } from "~/hooks/useSettingsMenu";
import { SubscriptionStatus } from "~/components/layout/Sidebar";

export const SubscriptionButton = ({
  status,
}: {
  status: SubscriptionStatus | undefined;
}) => {
  const [isOpenSubscriptionModal, setIsOpenSubscriptionModal] = useState(false);
  const [, handleSetActiveHeaderMenu] = useSettingsMenu();

  switch (status) {
    case SubscriptionStatus.TRIAL:
      return (
        <div className="p-2">
          <Button
            className="w-full"
            onClick={() => setIsOpenSubscriptionModal(true)}
          >
            Upgrade Plan
          </Button>
          <SubscriptionsPlansModal
            isOpen={isOpenSubscriptionModal}
            onClose={() => setIsOpenSubscriptionModal(false)}
          />
        </div>
      );

    case SubscriptionStatus.ACTIVE:
      return (
        <div className="p-2">
          <Link href="/settings" className="w-full">
            <Button
              onClick={() => handleSetActiveHeaderMenu("Subscription")}
              className="w-full"
            >
              View Plan
            </Button>
          </Link>
        </div>
      );

    default:
      return null;
  }
};
